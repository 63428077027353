import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import PuxImageContent, {
  PuxImageContentType,
} from '../PuxImageContent/PuxImageContent'

export interface WidgetPuxImageContentType
  extends WidgetBase,
  PuxImageContentType {
  contentType: 'PuxImageContent'
}

const WidgetPuxImageContent: FunctionComponent<WidgetPuxImageContentType> = (
  props
) => (
  <WidgetContainer
    {...props.puxWidgetProperties}
    {...props.puxWidgetAnimation}
    {...props.metadata}
  >
    <PuxImageContent
      imageContentText={props.imageContentText}
      imageContentImagePosition={props.imageContentImagePosition}
      imageContentButtonAlign={props.imageContentButtonAlign}
      puxMediaSelector={props.puxMediaSelector}
      puxButton={props.puxButton}
      puxVideo={props.puxVideo}
    />
  </WidgetContainer>
)

export default WidgetPuxImageContent
